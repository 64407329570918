.flex-vertical-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* DEFAULT (RED) THEME */

.demand-list {
  margin: 1em 0;
}

.demand-list .accordion {
  width: 100%;
}

.demand-list .item {
  display: flex;
  width: 100%;
  justify-content: center;
}

.demand-list .item .title {
  cursor: pointer;
  background-color: #e21833;
  color: white;
  font-family: 'IBM Plex Sans', Tahoma, Geneva, Verdana, sans-serif;

  padding: 0.75em 1.25em;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.demand-list .item .icon {
  width: 2em;
  height: 2em;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-weight: bold;
  font-size: 1.5em;

  background-color: white;
  color: #e21833;
  border-radius: 50%;
}

.demand-list .item .header {
  width: fit-content;
  align-self: center;
  font-size: 1.25em;

  margin-left: 1em;

  font-weight: 500;
}

.demand-list .card-body {
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: 1.1em;
  line-height: 1.75em;
}

.card-body .illustration-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-body .illustration-container img {
  width: 100%;
  height: auto;
  max-height: 350px;
  object-fit: contain;

  margin-bottom: 0.5em;
}

.card-body .illustration-container p.caption {
  font-size: 0.8em;
  color: gray;
  font-style: italic;
}

.demand-list .card-body a {
  color: #e21833;
}

.demand-list .card-body h3 {
  margin-top: 1em;
  font-style: italic;
}

.flourish-embed.flourish-chart {
  margin: auto;
  width: 90%;
}

/* WHITE THEME */

.demand-list.white .item .title {
  background-color: white;
  color: black;
}
.demand-list.white .item .icon {
  background-color: #e21833;
  color: white;
}

/* Tablet styles */
@media screen and (max-width: 1023px) {
  .demand-list {
    font-size: 1.2em;
  }

  .flourish-embed.flourish-chart {
    width: 100%;
  }
}

/* Mobile styles */
@media screen and (max-width: 767px) {
  .demand-list .item .title {
    padding: 0.75em 1.25em;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .demand-list .item .icon {
    width: 2em;
    height: 2em;

    font-weight: bold;
    font-size: 1.25em;
  }

  .demand-list .item .header {
    margin: 0.6em 0;
  }
}
