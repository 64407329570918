#landing {
  width: 100%;
  height: 100vh;

  background:
    linear-gradient(rgba(0,0,0,0) 65%, rgba(0,0,0,0.65) 90%, rgba(0,0,0,1) 100%),
    url('https://dbknews-assets.s3.amazonaws.com/umddemands.dbknews.com/landing/landing-bg.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}

#landing-title-container {
  color: #e21833;
  font-family: 'Solano Gothic BD';
  margin-bottom: 2.5em;
}

#landing-title-container > h1 {
  font-size: 20vh;
  text-align: center;
}

/* Mobile styles */
@media screen and (max-width: 767px) {
  #landing-title-container > h1 {
    font-size: 12.5vh;
  }
}
