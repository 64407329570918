#bio-container {
  margin: 1em 0;
}

#bio-elems {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: space-evenly;
}

.profile {
  width: 10em;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 1.5em;
}

.picture-container {
  width: 10em;
  height: 10em;
  margin: 0 1em;

  border-radius: 50%;

  /* Visually, this is redundant given the filter rules in `.picture-container > img`
  but is necessary to get the text overlay centered on this div */
  filter: brightness(100%);
  -webkit-filter: brightness(100%);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.picture-container > img {
  width: inherit;
  height: inherit;
  border-radius: 50%;

  object-fit: cover;

  /* Image shows up normally */
  filter: brightness(100%);
  -webkit-filter: brightness(100%);

  /* Transition rules */
  -webkit-transition: all 250ms ease;
  -moz-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  -ms-transition: all 250ms ease;
  transition: all 250ms ease;
}

.picture-container:hover > img {
  /* Image dims a bit when hovered on */
  filter: brightness(25%);
  -webkit-filter: brightness(25%);

  cursor: pointer;
}

.picture-container > p.text-overlay {
  /* Hide text overlay */
  display: none;
}

.picture-container:hover > p.text-overlay {
  /* Text overlay is displayed on hover */
  display: block;
  position: fixed;
  z-index: 1;

  font-size: 1.5em;
  margin: 0;
  padding: 0;

  /* Ensures the cursor remains a pointer even if it goes over this element */
  cursor: pointer;

  text-align: center;
  color: white;
}

.profile > p.profile-title {
  font-family: 'IBM Plex Sans';
  font-size: 1.35em;
  text-align: center;
  color: black;
}

img.carousel-item {
  width: auto;
  height: 100%;

  object-fit: contain;
}

.modal-body .carousel.carousel-slider li {
  max-height: 325px;
}
