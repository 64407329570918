@font-face {
  font-family: "IBM Plex Sans";
  src: url('./fonts/IBMPlexSans-Regular.ttf') format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: url('./fonts/IBMPlexSans-Medium.ttf') format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: url('./fonts/IBMPlexSans-SemiBold.ttf') format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: url('./fonts/IBMPlexSans-Bold.ttf') format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Solano Gothic BD';
  src: url('./fonts/SolanoGothicMVB-BdCap.otf') format("truetype");
  font-style: normal;
}
